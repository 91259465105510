import React from 'react';
import { Link } from 'gatsby';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';
import config from '../../data/SiteConfig';

const Footer = () => (
  <footer className="site-footer bg-gray-800 text-gray-100 px-4 text-center">
    <div className="mx-auto max-w-md py-8 mx-auto w-full">
      <div className="my-2 grid grid-cols-2 gap-2 uppercase tracking-wider text-sm">
        <Link to="/services/">Services</Link>
        <Link to="/testimonials/">Testimonials</Link>
        <Link to="/case-studies/">Case Studies</Link>
        <Link to="/workshops/testing/">Testing Workshop</Link>
        {/* <a href="https://serverlesstestinghandbook.com">Testing Handbook</a> */}
        <Link to="/articles/">Articles</Link>
        <a href="https://notes.paulswail.com">Paul’s Public Notes</a>
        <Link to="/newsletter/">Newsletter</Link>
        <Link to="/emails/">Emails Archive</Link>
        <Link to="/about/">About</Link>
        <Link to="/privacy/">Privacy</Link>
        <Link to="/referral/">Refer me</Link>
        <Link to="/contact/">Contact</Link>
      </div>
      <div className="line my-5">
        © Winter Wind Software Ltd. Registered in N.Ireland NI619811.
      </div>
      <div className="flex justify-center space-x-6 text-2xl">
        <a href={config.twitterProfileUrl}>
          <FaTwitter title="Twitter" />
        </a>
        <a href={config.githubProfileUrl}>
          <FaGithub title="GitHub" />
        </a>
        <a href={config.linkedInProfileUrl}>
          <FaLinkedin title="LinkedIn" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
